<template>
  <base-section id="termsandconditions">
    <v-container>
      <base-subheading
        size="display-1"
        title="General Terms and Conditions for Talents | ScrumOnDemand"
        space="15"
        align="center"
      />

      <base-body>
        These General Terms and Conditions for Talents (these “<strong>GTC</strong>”) effective as of your (“<strong>Talent</strong>”) registration
        to use the Website (as defined in Section 1.1 below), are by and between Talent and Elyon Innovations
        LLC d/b/a ScrumOnDemand, a Washington corporation (“<strong>ScrumOnDemand</strong>”), and governs Talent’s and ScrumOnDemand’s
        respective rights and obligations with respect to Talent’s use of the Website and talent  fulfillment of Projects
        (as defined in Section 1.1 below).
      </base-body>

      <base-title
        title="1. Purpose and Use of the Website"
        tag="div"
        space="6"
      />

      <base-body>
        <strong>1.1</strong> ScrumOnDemand operates the website ScrumTeamOnDemand.com (“<strong>Website</strong>”), through which it provides customers
        (“<strong>Customers</strong>”) a platform for the fulfillment of certain project commissions (“<strong>Projects</strong>”) to be fulfilled by
        individual or teams of qualified talent (“<strong>Talent</strong>”) registered with the Website. Once a Project is completed,
        the work product of the Project (the “<strong>Result</strong>”), including without limitation the project completion report,
        is made available to the Customer by Talent.  ScrumOnDemand is in no way involved in, or responsible or liable
        for, fulfilling the Projects.
      </base-body>

      <base-body>
        <strong>1.2</strong>  The Website is arranged, operated, and managed by ScrumOnDemand in the state of ‎Washington,
        United States of America. These GTC and all agreements related to Projects shall be ‎governed exclusively by
        the internal laws of the State of Washington and the United States of America, without giving effect to any
        principles of ‎conflicts of laws that would result in the jurisdiction of another state or country governing
        these GTC. Talent agrees that any action at law or in equity arising out of or relating to these ‎GTC shall be
        submitted to confidential arbitration in the city of Vancouver, ‎Washington, except that, to the extent Talent
        has in any manner violated or threatened to violate ‎ScrumOnDemand’s or a Customer’s intellectual property rights,
        ScrumOnDemand and the Customer may seek injunctive or other ‎appropriate relief in any state or federal court in of
        its choosing, and the Talent hereby irrevocably consents to ‎the exclusive jurisdiction and venue in such courts and
        waives any objection to such venue such as forum non conveniens or otherwise. Arbitration under these GTC shall be
        conducted ‎under the rules then prevailing of the American Arbitration Association. The arbitrator's award shall be
        ‎binding and may be entered as a judgment in any court of competent jurisdiction. To the fullest extent ‎permitted by
        applicable law, no arbitration under these GTC shall be joined to an arbitration involving ‎any other party subject to
        these GTC, whether through class arbitration proceedings or otherwise.
      </base-body>

      <base-body>
        <strong>1.3</strong>  ScrumOnDemand has no control over the quality, safety, accuracy, fitness for a particular
        purpose or use, compliance with laws, or legality of the Result, the ability of Talent to complete the Project or
        Result to the Customer’s satisfaction, or the ability of Customer to pay for the Result, and ScrumOnDemand hereby
        disclaims all representations and warranties and liability arising from or related to the same. ScrumOnDemand has
        no control over, or obligations or responsibility or liability with respect to, the Project, the Result, and/or
        the specifications, timelines, budget, or payment for the Project or Result; and all of the foregoing shall be decided
        between and shall be the responsibility of Customer and Talent. ScrumOnDemand will not enter into a contract with
        Customer on Talent’s behalf, and Customer and Talent are responsible for entering into a contract agreeable to each of them.
      </base-body>

      <base-body>
        <strong>1.4</strong>  By registering for and using the Website, Talent represents and warrants that Talent (1)
        is a natural person who is at least eighteen (18) years old; (2) has the authority to enter into, and be bound by,
        these GTC, and (3) agrees to be bound by these GTC, including the terms and condition of all applicable policies,
        procedures, and guidelines set forth in these GTC.
      </base-body>

      <base-body>
        <strong>1.5</strong>  Talent acknowledges and agrees that the performance of any Project will not, under any
        circumstance, create an employment, agency, or partnership relationship between the Customer, on the one hand,
        and Talent, on the other hand. Talent will not represent him or herself as an employee, partner, or agent of
        the Customer, and Talent will have no authority to bind the Customer.  Talent’s relationship with ScrumOnDemand
        will be determined by the Professional Services Agreement between the parties (“<strong>Relationship Agreement</strong>”).
      </base-body>

      <base-body>
        <strong>1.6</strong>  Talent agrees to comply with all applicable laws and regulations when using the Website and performing Projects.
      </base-body>

      <base-body>
        <strong>1.7</strong>  Notwithstanding any other provision of these GTC, ScrumOnDemand will have the right, in its sole
        discretion, to determine the content, appearance, design, functionality and all other aspects of the Website
        (including the right to re-design, modify, remove, and alter the content, appearance, design, navigation, functionality
        and other aspects of the Website and/or any page thereof and any element, aspect, portion or feature thereof, from time
        to time).
      </base-body>

      <base-title
        title="2. Registration"
        tag="div"
        space="6"
      />

      <base-body>
        <strong>2.1</strong>  In order to view and fulfill Projects, Talent must register on the Website using the Talent Registration Form
        on the Website and maintain Talent’s registration in good standing by diligently performing the services required in each
        Project that Talent undertakes and by remaining in strict compliance with these GTC.
      </base-body>

      <base-body>
        <strong>2.2</strong>  When registering or updating its information, Talent shall not impersonate any person or use a name that Talent
        is not legally authorized to use.
      </base-body>

      <base-body>
        <strong>2.3</strong>  ScrumOnDemand reserves the right, at its sole discretion and for any reason, to deny registration to Talent,
        or to cancel the registration of Talent after registration.
      </base-body>

      <base-body>
        <strong>2.4</strong>  Talent shall ensure that all information provided in the Talent Registration Form is true, complete, and
        correct and shall be responsible for updating any documentation that is incorrect or out of date. Talent hereby authorizes
        ScrumOnDemand to verify and validate all information provided by Talent in the Talent Registration Form, including without
        limitation the authenticity of Talent’s claimed certificates and accreditations, and Talent agrees to provide ScrumOnDemand with
        evidence of Talent’s certificates and accreditations at ScrumOnDemand’s request. Talent’s failure to comply with ScrumOnDemand’s
        requests to provide information corroborating the information submitted by Talent in the Talent Registration Form will be grounds
        for ScrumOnDemand denying Talent’s registration.
      </base-body>

      <base-body>
        <strong>2.5</strong>  Upon Talent’s registration, a user profile and a user account for Talent are created, and a username and a
        personalized password are assigned to Talent. Talent is solely responsible for maintaining the secrecy and security of his or
        her password. Talent may not disclose his or her password to any third party and is solely responsible for any use of or action
        taken under his or her username and password, regardless of whether Talent knew of or permitted such use or action. If Talent’s
        password is compromised, then Talent must change his or her password immediately.
      </base-body>

      <base-body>
        <strong>2.6</strong>  Talent may only register once on the Website.  Talent’s registration and user account are not transferable
        in any manner. Talent shall not permit any other person or entity to fulfill Projects on Talent’s behalf.
      </base-body>

      <base-body>
        <strong>2.7</strong>  Talent agrees that it is Talent’s responsibility and sole obligation to determine whether and to what
        extent any permits, registrations, authorization, or filings (including without limitation with respect to the transfer of technology)
        are required by any governmental agency in any jurisdiction in which Talent is performing a Project and producing a Result
        (hereinafter “<strong>Permits</strong>”).  TALENT ACKNOWLEDGES AND AGREES THAT SCRUMONDEMAND AND ITS AFFILIATES WILL NOT AND ARE NOT OBLIGATED
        TO DETERMINE WHETHER ANY SUCH PERMITS APPLY TO ANY PROJECTS.
      </base-body>

      <base-title
        title="3. Submission and Fulfillment of Projects"
        tag="div"
        space="6"
      />

      <base-body>
        <strong>3.1</strong>  Available Projects can be viewed by Talent while logged into the Website.
      </base-body>

      <base-body>
        <strong>3.2</strong>  The assignment of each Project is at the sole discretion of ScrumOnDemand and the Customer, and Talent shall not be
        entitled to view (prior to assignment to Talent) or be assigned to any or all Projects or any specific Project. ScrumOnDemand makes no
        guarantee, representation, or warranty that Talent will be assigned one or more Projects.
      </base-body>

      <base-body>
        <strong>3.3</strong>  Once the Project has been assigned to Talent and Talent agrees to perform the Project, Talent will receive information,
        specifications and guidelines as to the completion of the Project (“Specifications”), in each case as submitted by the Customer. Talent will
        complete the Project in strict conformance with the Specifications.
      </base-body>

      <base-body>
        <strong>3.4</strong>  Talent will transmit the finished Result to the Customer no later than the deadline provided by the Customer.
      </base-body>

      <base-body>
        <strong>3.5</strong>  If Talent does not complete the Project by the deadline set for the Project by the Customer, ScrumOnDemand reserves
        the right (without limiting other rights of ScrumOnDemand pursuant to these GTC) to reduce the number or type of Projects to which Talent
        has access or suspend the Talent from the Website.
      </base-body>

      <base-body>
        <strong>3.6</strong>  If the Result is not completed on time or is not completed in accordance with the Specifications or to the Customer’s
        satisfaction, in its sole discretion, the Customer is entitled to reject the Result and repost the Project for completion by another Talent,
        and neither the Customer nor ScrumOnDemand shall have any obligation to pay the fee offered by the Customer to Talent in consideration for
        Talent’s fulfillment of the Project.
      </base-body>

      <base-body>
        <strong>3.7</strong>  Talent’s submission, fulfillment, and completion of a Project and the Result, and Talent’s provision of the Result to
        the Customer, must not violate any law or any intellectual property rights, proprietary rights, rights of publicity, privacy rights, or any
        other rights of Customer, of ScrumOnDemand, or of a third party. If ScrumOnDemand becomes aware of Talent’s violation of any of the foregoing
        with respect to a Project or a Result, ScrumOnDemand reserves the right to remove Talent from such Project or Result, as applicable, and/or
        from the Website, provided that Talent will be given an opportunity to cure the violation within 48 hours of receiving notice of the violation,
        if the violation is curable. ScrumOnDemand further reserves the right to remove any Project that ScrumOnDemand reasonably determines to violate
        any applicable law or third party rights.  ScrumOnDemand makes no representation or warranty about the lawfulness of any Project available on
        the Website or of any Result.
      </base-body>

      <base-body>
        <strong>3.8</strong>  Talent acknowledges that the risk of loss of data stored on the server of ScrumOnDemand cannot be completely excluded.
        Talent will, therefore, maintain backup copies of all data and information uploaded or submitted to the Website.
      </base-body>

      <base-title
        title="4. Confidentiality"
        tag="div"
        space="6"
      />

      <base-body>
        <strong>4.1</strong>  All information that Talent receives in connection with the submission, acceptance, and fulfillment of a Project and/or
        use of the Website ( “<strong>Confidential Information</strong>”), is to be kept strictly confidential by Talent, and Talent will use the same measures to
        protect the confidentiality of the Confidential Information as Talent uses to protects its own information of like kind, but in no event
        less than reasonable measures. Customer’s Confidential Information shall include, without limitation, any data or information related to
        the Customer as well as the Specifications , the Project, and the Result. Confidential Information will not include information that: (a)
        is public knowledge at the time of disclosure to Talent; (b) has subsequently become public knowledge other than as a result of Talent’s
        breach of this Section 4; (c) is known to Talent at the time of disclosure (other than in connection with a relationship between Talent and
        Customer or Talent and ScrumOnDemand), and if Talent is not bound to any prior confidentiality or fiduciary obligations related to such
        information; or (d) has been lawfully provided to Talent without any confidentiality or fiduciary obligations by a third party.
      </base-body>

      <base-body>
        <strong>4.2</strong>  Talent will have a limited, non-exclusive, non-assignable, non-sublicensable, non-transferable, revocable (at any time,
        at the Customer’s sole discretion) right to use the Customer’s Confidential Information, solely for the Talent’s fulfillment of the applicable
        Project. ScrumOnDemand hereby grants to Talent a limited, non-exclusive, non-assignable, non-sublicensable, non-transferable, revocable (at any
        time, at ScrumOnDemand’s sole discretion) right to use ScrumOnDemand’s Confidential Information, solely in connection with Talent’s use of the
        Website and fulfillment of the Projects. This license shall exist solely during the term of Talent’s use of the Website, and the license shall
        immediately and automatically terminate when Talent no longer uses the Website.  The Talent retains no rights to reproduce or use such
        Confidential Information.
      </base-body>

      <base-body>
        <strong>4.3</strong>  Upon the request of ScrumOnDemand or the Customer, all Confidential Information (including copies, summaries, and excerpts
        thereof) shall be promptly and completely returned or destroyed. Talent will certify such destruction at the request of ScrumOnDemand.
        Talent shall not challenge the validity of the Customer’s or ScrumOnDemand’s ownership of the Confidential Information.
      </base-body>

      <base-body>
        <strong>4.4</strong>  In the event Talent is legally compelled or required, by subpoena, civil investigative demand, or other court or
        governmental process to disclose any Confidential Information, Talent must, if legally permitted, notify ScrumOnDemand promptly so that it
        may seek a protective order or other appropriate remedy or, in ScrumOnDemand’s sole discretion, waive in writing Talent’s compliance with
        the terms of this Section 4. Talent agrees to comply with any protective order or other remedy obtained by ScrumOnDemand to limit the disclosure
        or otherwise protect the Confidential Information. Talent will reasonably cooperate, at ScrumOnDemand’s expense, with any efforts by it to
        obtain confidential treatment of its Confidential Information. In all instances, Talent will furnish only that portion of ScrumOnDemand’s
        Confidential Information that Talent believes in good faith, after consulting with legal counsel, it is legally required to disclose pursuant
        to the subpoena, civil investigative demand, or other court or governmental process, as applicable.
      </base-body>

      <base-body>
        <strong>4.5</strong>  Notwithstanding anything to the contrary in this Agreement, Talent has the right to disclose in confidence ScrumOnDemand’s
        Confidential Information to government officials or to any attorney for the sole purpose of reporting or investigating a suspected violation of
        law. Talent also has the right to disclose ScrumOnDemand’s Confidential Information in a document filed in a lawsuit or other proceeding, but
        only if the filing is made under seal and protected from public disclosure. Talent will not be held criminally or civilly liable for disclosure
        of a trade secret under those limited circumstances. Nothing in this Agreement is intended to create liability for disclosure of trade secrets
        that are expressly allowed by 18 U.S.C. § 1833(b). ScrumOnDemand requests that Talent inform ScrumOnDemand of any disclosure that will be made
        under this Section 4.5.
      </base-body>

      <base-title
        title="5. Property Rights in Results"
        tag="div"
        space="6"
      />

      <base-body>
        <strong>5.1</strong>  The Customer for whom Talent fulfills the Project is Talent’s client, and as such, Talent agrees that the Results is, was,
        and will be (i) specially ordered and commissioned for use by the Customer, (ii) made by Talent for Customer, and (iii) regarded as a “work made
        for hire,” as that term is used in the U.S. Copyright Act, 17 U.S.C. 101, et. seq., by Talent for Customer to the extent it qualifies as such
        under applicable law, and all ownership rights, including worldwide intellectual property rights, will vest with the Customer.
      </base-body>

      <base-body>
        <strong>5.2</strong>  Talent expressly acknowledges and agrees that it is a material and core component of the Customer’s ‎bargain that the
        Results will be and remain the sole and exclusive ‎property of the Customer.  ‎To the extent all rights in the Results do not vest in the Customer
        under Section 5.1, Talent hereby unconditionally and irrevocably assigns, grants, and delivers exclusively to the Customer commissioning the
        Project (without the right to any additional compensation or royalties) all right, title and interest of every kind and nature whatsoever in and
        to the Results, including without limitation all worldwide copyright and trademark rights, any and all rights necessary for licensing and
        merchandising, including rights of privacy and publicity, any and all moral rights, and any and all other intellectual property and proprietary
        rights in the Results, and all applications and registrations therefor. For the avoidance of doubt, Talent may not use, modify, create
        derivative works based on, copy, sell, distribute, transfer, display, publish, or otherwise exploit the Results.
      </base-body>

      <base-body>
        <strong>5.3</strong>  To the extent the foregoing assignments are ineffective for any reason, Talent hereby grants to the Customer, and its
        successors, assigns and licensees, the exclusive, fully-paid-up, irrevocable, perpetual, transferable, sublicensable, and worldwide right
        and license to reproduce, modify, make derivative works of, display, publish, distribute, sell, transfer, use, and otherwise exploit the
        Results and all copyright, trademark, and other rights necessary for licensing and merchandising, including rights of privacy and publicity;
        any and all moral rights; and all other intellectual property and proprietary rights, and all applications and registrations therefor.
        To the extent the foregoing license is ineffective for any reason, Talent hereby irrevocably and perpetually waives all rights Talent may
        have in or to the Results to the sole benefit of the Customer, and covenants not to bring or participate in any action against the Customer
        or its successors, assigns or licensees for infringement, misappropriation, or other violation of such rights.
      </base-body>

      <base-body>
        <strong>5.4</strong>  Talent shall assist the Customer in evidencing and perfecting the Customer’s rights in the Results in every way,
        including without limitation by signing any documents or instruments reasonably required to obtain and enforce the intellectual property
        rights to the Results.
      </base-body>

      <base-body>
        <strong>5.5</strong>  Talent represents and warrants that Talent will not incorporate into the Results any intellectual property developed by
        Talent other than during the Talent’s fulfillment of the Project.
      </base-body>

      <base-body>
        <strong>5.6</strong>  The Talent shall not challenge the validity of the Customer’s ownership in the Results. The Talent waives any moral
        rights, rights of paternity, integrity, disclosure and withdrawal or inalienable rights under applicable law in and to the Results,
        including any right he or she may have to be named in connection with any publication of the Result, if published (as the author thereof).
      </base-body>

      <base-body>
        <strong>5.7</strong>  Talent represents and warrants that he or she has the power and authority to assign, transfer and grant all such
        aforementioned rights to the Customer.
      </base-body>

      <base-body>
        <strong>5.8</strong>  Talent further represents and warrants that the Result and the fulfillment of the Project does not violate any rights,
        including any intellectual property rights, proprietary rights, rights of publicity, and/or privacy rights, of ScrumOnDemand, of the Customer,
        or of any third party.
      </base-body>

      <base-title
        title="6. Payment, User Account and Taxes"
        tag="div"
        space="6"
      />

      <base-body>
        <strong>6.1</strong>  The parties’ respective payment and tax obligations arising from or related to these GTC will be set forth
        in the Relationship Agreement.
      </base-body>

      <base-title
        title="7. Liability"
        tag="div"
        space="6"
      />

      <base-body>
        <strong>7.1</strong>  THE WEBSITE IS PROVIDED ON AN “AS IS” BASIS. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, SCRUMONDEMAND
        MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AND SCRUMONDEMAND HEREBY DISCLAIMS ALL REPRESENTATIONS AND
        WARRANTIES, INCLUDING, WITHOUT LIMITATION: (A) ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
        VALIDITY OF RIGHTS IN, OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS, WITH RESPECT TO THE WEBSITE, ALL PROJECTS, AND ALL RESULTS; (B)
        THAT THE WEBSITE WILL MEET TALENT’S REQUIREMENTS, WILL ALWAYS BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE, OPERATE WITHOUT
        ERROR, OR WILL CONTAIN ANY PARTICULAR FEATURES OR FUNCTIONALITY;  (C) THAT THE INFORMATION, CONTENT, OR MATERIALS (AS DEFINED IN
        SECTION 9.8 BELOW) INCLUDED ON THE WEBSITE WILL BE AS REPRESENTED BY CUSTOMERS, THAT THE RESULTS ARE LAWFUL, OR THAT CUSTOMERS WILL
        PERFORM AS PROMISED; OR (D) ANY IMPLIED WARRANTY ARISING FROM COURSE OF DEALING OR USAGE OF TRADE.
      </base-body>

      <base-body>
        <strong>7.2</strong>  BECAUSE SCRUMONDEMAND IS NOT INVOLVED IN TRANSACTIONS BETWEEN CUSTOMERS AND TALENT OR OTHER PARTICIPANT DEALINGS,
        TALENT HEREBY RELEASES SCRUMONDEMAND AND ITS AFFILIATES (AND THEIR RESPECTIVE EMPLOYEES, OFFICERS, DIRECTORS, AGENTS, AND REPRESENTATIVES)
        FROM ANY AND ALL CLAIMS, SUITS, ACTIONS, DEMANDS, LOSSES, LIABILITIES, COSTS, EXPENSES, ATTORNEYS’ FEES, JUDGEMENTS, PENALTIES, INTEREST,
        AND DAMAGES (ACTUAL, CONSEQUENTIAL, AND OTHERWISE) OF EVERY KIND AND NATURE, KNOWN AND UKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND
        UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY DISPUTE BETWEEN TALENT AND THE CUSTOMERS, OTHER USERS OF THE WEBSITE, OR
        ANY THIRD PARTY.
      </base-body>

      <base-body>
        <strong>7.3</strong>  Talent shall indemnify and hold harmless ScrumOnDemand and its affiliates and its and their respective employees,
        officers, directors, agents, and representatives for, from, and against any and all claims, costs, losses, liabilities, damages, judgments,
        penalties, interest and expenses (including reasonable attorneys’ fees) arising out of any claim, action, suit, audit, investigation,
        inquiry or other proceeding (hereinafter “Claim”) that arises out of or relates to: (a) any actual or alleged breach of Talent’s representations
        and warranties, or obligations set forth in these GTC; (b) Talent’s fulfillment of or failure to fulfill a Project; (c) the Result, or the
        making available of the Result, including without limitation, any claim or allegation that the Result infringes, misappropriates, or
        violates any Customer’s or third party’s rights of any kind; and/or (d) Talent’s failure to comply with any applicable laws and regulations
        in connection with Talent’s use of the Website and fulfillment of Projects and provision of Results.
      </base-body>

      <base-body>
        <strong>7.4</strong>  TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, SCRUMONDEMAND AND ITS AFFILIATES WILL NOT BE LIABLE FOR ANY INDIRECT,
        INCIDENTAL, , SPECIAL, PUNITIVE, EXEMPLARY, ENHANCED, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH THESE GTC, THE WEBSITE,
        THE PROJECTS, THE INABILITY TO USE THE WEBSITE OR UNAVAILABILITY OF THE WEBSITE OR ERROR IN THE WEBSITE, OR ANY RESULTS, OR MESSAGES
        RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH THE WEBSITE.
      </base-body>

      <base-body>
        <strong>7.5</strong>  TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL SCRUMONDEMAND’S OR ITS AFFILIATES’ AGGREGATE
        LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE GTC OR THE TRANSACTIONS CONTEMPLATED HEREBY, WHETHER IN CONTRACT, TORT (INCLUDING
        NEGLIGENCE, PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY), WARRANTY, OR OTHERWISE, EXCEED THE AMOUNT OF COMMISSIONS EARNED BY
        SCRUMONDEMAND IN CONNECTION WITH THE PROJECTS FULFILLED BY TALENT DURING THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT
        GIVING RISE TO THE CLAIM FOR LIABILITY.
      </base-body>

      <base-body>
        <strong>7.6</strong>  ScrumOnDemand does not guarantee there will be no, and does not assume or accept any liability in connection with,
        any interruption or failure in the availability or functionality of the Website and the services offered on the Website.
      </base-body>

      <base-body>
        <strong>7.7</strong>  ScrumOnDemand is not responsible for the actions of any Customer or Talent.
      </base-body>

      <base-body>
        <strong>7.8</strong>  ScrumOnDemand (and its affiliates) will only be responsible for the transfers and disbursements of funds deposited by
        Customers, at the direction of Customers and Talent.  ScrumOnDemand will be entitled to rely on the instructions of Customers and Talent
        without any further inquiry or liability whatsoever.  ScrumOnDemand will not be liable if ScrumOnDemand is not able to complete a transaction
        for any reason, including, but not limited to:

        <ol type="a">
          <li>If any system or equipment was not working properly;</li>
          <li>If the transfer would cause Talent to exceed any applicable transfer limit with respect to Talent’s ACH-enabled bank account;</li>
          <li>
            If circumstances beyond the control of ScrumOnDemand (such as, but not limited to, power outages, fire, flood, epidemic, mechanical or systems failure)
            prevent the proper execution of the transaction;
          </li>
          <li>
            If Talent’s transaction is intercepted by legal process or other encumbrances restricting transfer, or Talent’s participation on the Website has been terminated
            or suspended for any reason;
          </li>
          <li>If ScrumOnDemand is unable to confirm Talent’s identity or has reason to believe that the transfer requested is unauthorized; or</li>
          <li>If Talent has not provided ScrumOnDemand with correct, current, and complete payment information.</li>
        </ol>
      </base-body>

      <base-body>
        <strong>7.9</strong>  If Talent believes that any payment transaction initiated by ScrumOnDemand (or an agent of ScrumOnDemand) is erroneous, or if Talent needs
        more information about any transaction, Talent should contact ScrumOnDemand as soon as possible.
      </base-body>

      <base-title
        title="8. Termination"
        tag="div"
        space="6"
      />

      <base-body>
        <strong>8.1</strong>  Talent may elect at any time to stop using the Website and terminate Talent’s user account. Such termination of Talent’s user account will
        terminate these GTC, and ScrumOnDemand will disable Talent’s access to the Website.
      </base-body>

      <base-body>
        <strong>8.2</strong>  ScrumOnDemand, in its sole discretion, may terminate these GTC, suspend or terminate Talent’s access to the Website, or remove any Project
        listings without notice and for any reason.
      </base-body>

      <base-body>
        <strong>8.3</strong>  ScrumOnDemand may terminate these GTC at any time if Talent violates the terms and conditions of these GTC, provided that ScrumOnDemand can
        choose to  provide Talent with a reasonable cure period in ScrumOnDemand’s sole discretion. ScrumOnDemand may suspend or terminate Talent’s right to access and
        use the Website and/or Talent’s user account in the event that ScrumOnDemand reasonably believes that Talent has breached any of the terms and conditions in these GTC.
      </base-body>

      <base-body>
        <strong>8.4</strong>  Upon termination of these GTC for any reason and by either party, ScrumOnDemand will disable Talent’s access to the Website,
        and Talent shall not use or attempt to use the Website. , Upon termination of these GTC for any reason and by either party, except in the case these GTC are
        terminated by ScrumOnDemand for cause, any existing balance of the credit on Talent’s user account will be paid to Talent.
      </base-body>

      <base-title
        title="9. Data Collection and Protection"
        tag="div"
        space="6"
      />

      <base-body>
        <strong>9.1</strong>  The parties recognize and agree that ScrumOnDemand must collect, use, disclose, store, and transfer the personal information of Talent
        to the Customer in connection with the Projects.
      </base-body>

      <base-body>
        <strong>9.2</strong>  ScrumOnDemand’s privacy policy (“<strong>Privacy Policy</strong>”), which can be found on the Website, is included, and incorporated in
        these GTC by reference.
      </base-body>

      <base-body>
        <strong>9.3</strong>  The Privacy Policy and these GTC describe ScrumOnDemand’s collection, use, disclosure, storing, and transfer of information collected
        through the Website, including how ScrumOnDemand handles personal information.
      </base-body>

      <base-body>
        <strong>9.4</strong>  In addition to the disclosures described in the Privacy Policy, ScrumOnDemand may disclose to the Customers Talent’s name, address,
        Social Security Number, tax identification information, and any other data on the Talent Registration Form submitted by Talent.  Talent hereby consents to
        ScrumOnDemand’s use and disclosure of such information and other data as described in these GTC and the Privacy Policy.
      </base-body>

      <base-body>
        <strong>9.5</strong>  Talent may use information or other data acquired from Talent’s use of the Website solely to the extent necessary for Talent to use the
        Website and fulfill the Projects and for no other purpose, including but not limited to, for purposes of solicitation, advertising, marketing, unsolicited
        e-mail or spamming, harassment, invasion of privacy, or otherwise objectionable conduct.
      </base-body>

      <base-body>
        <strong>9.6</strong>  ScrumOnDemand reserves the right to implement mechanisms allowing it and others to track requests for, and Talent’s fulfillment of, the Projects.
      </base-body>

      <base-body>
        <strong>9.7</strong>  ScrumOnDemand reserves the right to rate Talent’s performance, collect feedback regarding Talent’s performance from the Customers, and to
        publically post such ratings and feedback on the Website.  Talent may not take any actions that may undermine the integrity of any feedback system.
      </base-body>

      <base-body>
        <strong>9.8</strong>  Talent grants to ScrumOnDemand a royalty-free, non-exclusive, worldwide, perpetual, irrevocable right and license to use, reproduce,
        perform, display, distribute, adapt, modify, re-format, create derivative works of, and otherwise commercially or non-commercially exploit in any manner, any
        and all information, feedback, content, data or other materials  posted to the Website by or on behalf of Talent (collectively, “<strong>Materials</strong>”), and to
        sublicense the foregoing rights, in connection with the operation and maintenance of the Website.
      </base-body>

      <base-body>
        <strong>9.9</strong>  Talent agrees that submission of any Materials is at its own risk, and that neither ScrumOnDemand nor the Customers have any obligations
        (including without limitation obligations of confidentiality or storage) with respect to the Materials.  Talent represents and warrants that it has all rights,
        power, and authority necessary to submit the Materials.
      </base-body>

      <base-body>
        <strong>9.10</strong>  The Talent represents and warrants that the Materials do not violate any rights, including any intellectual property rights, rights of
        publicity, and/or privacy rights, of any third party.  To the extent the Materials may be copyrightable, the Talent represents, warrants, and covenants that
        it is the owner of all the copyright rights to such Materials and that ScrumOnDemand may exercise the rights and licenses granted to it by Talent to the
        Materials without the need for further compensation or notice to Talent or any third party.
      </base-body>

      <base-title
        title="10. Use of the Website"
        tag="div"
        space="6"
      />

      <base-body>
        <strong>10.1</strong>  The Website may be used only for lawful purposes and in a lawful manner. Talent may not use the Website in any manner that violates
        any applicable law or governmental regulation.  ScrumOnDemand has the right, but not the obligation, to monitor any activity, content and Materials associated
        with the Website.  ScrumOnDemand may investigate any reported violation of its Policies (as defined in Section 11.1) or of this GTC or complaints and take any
        action or no action as it deems appropriate.
      </base-body>

      <base-body>
        <strong>10.2</strong>  Talent shall not copy, reproduce, modify, create derivative works from, distribute, or publicly display any software code that is part
        of the Website, any information or content that comprises the Website, or any services that are offered on the Website.
      </base-body>

      <base-body>
        <strong>10.3</strong>  Talent shall not interfere or attempt to interfere with the proper operation of the Website, or any activities conducted on the Website,
        nor bypass any measures ScrumOnDemand may use to prevent or restrict access to any part of the Website.
      </base-body>

      <base-body>
        <strong>10.4</strong>  Talent shall not attempt to interfere with or compromise the system integrity or security or decipher any transmissions to or from the
        servers running the Website, nor collect or harvest any personally identifiable information from the Website.
      </base-body>

      <base-body>
        <strong>10.5</strong>  Talent shall not access any content on the Website through any technology or means other than those provided or authorized by the
        Website, nor advertise or promote another website, product, or service or solicit other talents or Customers for other websites, products, or services.
        Without limiting the foregoing, from the first date Customer first uses the Website until Customer’s use of the Website is terminated, by either party
        and for any reason, and for a period of three (3) months thereafter, Talent shall not connect with or meet Customers through the Website and then perform
        services or work product for such Customers directly (i.e., not through the Website) to avoid ScrumOnDemand receiving its commission.
      </base-body>

      <base-body>
        <strong>10.6</strong>  Talent shall not post or introduce any virus, worm, or other harmful or malicious software code, agent, hidden procedure, routine,
        or mechanism through or to the Website or its software.
      </base-body>

      <base-body>
        <strong>10.7</strong>  Talent shall use the Website at Talent’s own risk.
      </base-body>

      <base-title
        title="11. General"
        tag="div"
        space="6"
      />

      <base-body>
        <strong>11.1</strong>  The terms and conditions set forth in these GTC, together with the Privacy Policy and all applicable policies, procedures and/or
        guidelines that appear on the Website from time to time (collectively, the “Policies”, which are hereby incorporated by reference into, and made part of,
        these GTC), constitute the entire agreement of the parties with respect to the subject matter hereof, and supersede and cancel all prior and contemporaneous
        agreements, claims, representations, and understandings of the parties in connection with the subject matter hereof. ScrumOnDemand will not be bound by, and
        specifically object to, any term, condition, or other provision that is different from or in addition to the provisions of these GTC, including when submitted
        by Talent in any order, invoice, bill, receipt, acceptance, confirmation, correspondence, or other document. Any modifications to these GTC by Talent must be
        in a writing signed by ScrumOnDemand.
      </base-body>

      <base-body>
        <strong>11.2</strong>  ScrumOnDemand reserves the right to change any of the terms and conditions contained in these GTC at any time, in its sole discretion.
        Any changes to these GTC, including the Policies, will be effective upon posting of the amended GTC or Policies on the Website and may be made without any
        other notice of any kind.  Talent is solely responsible for reading and understanding each version of the GTC and the Policies. TALENT’S CONTINUED USE OF
        THE WEBSITE FOLLOWING SCRUMONDEMAND’S POSTING OF ANY CHANGES TO THESE GTC OR THE POLICIES WILL CONSTITUTE TALENT’S ACCEPTANCE OF SUCH CHANGES. IF TALENT
        DOES NOT AGREE TO ANY CHANGES TO THESE GTC (INCLUDING TO ANY OF THE POLICIES INCORPORATED HEREIN), TALENT SHALL DISCONTINUE USE OF THE WEBSITE.
      </base-body>

      <base-body>
        <strong>11.3</strong>  Talent may not assign these GTC without ScrumOnDemand’s prior written consent. ScrumOnDemand may assign these GTC at any time,
        without notice. Subject to the foregoing, these GTC will be binding on each party's permitted successors and permitted assigns.
      </base-body>

      <base-body>
        <strong>11.4</strong>  Talent acknowledges and agrees that its violation or threatened to violation of these GTC may cause ScrumOnDemand irreparable
        harm for which monetary damages are not adequate and  ScrumOnDemand may seek injunctive or other appropriate equitable relief without the need to post
        bond or other security, and in addition to all other remedies available at law or in equity. All remedies under these GTC are cumulative and may be
        exercised concurrently or singularly.
      </base-body>

      <base-body>
        <strong>11.5</strong>  If any provision of these GTC is held illegal, invalid, or unenforceable in whole or in part under applicable law, such provision
        will be ineffective as to the jurisdiction in which it is illegal, invalid or unenforceable and will be deemed modified to the extent necessary to
        conform to applicable law so as to give the maximum effect to the original intent of the parties. The illegality, invalidity, or unenforceability
        of such provision in that jurisdiction will not in any way affect the legality, validity or enforceability of such provision in any other jurisdiction
        or of any other provision in any jurisdiction.
      </base-body>

      <base-body>
        <strong>11.6</strong>  ScrumOnDemand will not be considered to have waived any of its rights or remedies, or portion thereof, unless the waiver is in
        writing and signed by ScrumOnDemand. ScrumOnDemand's failure to enforce the strict performance of any provision of these GTC will not constitute a
        waiver of ScrumOnDemand's right to subsequently enforce such provision or any other provisions of these GTC.
      </base-body>

      <base-body>
        <strong>11.7</strong>  All notices relating to these GTC will be sent by e-mail or will be posted on the Website.  If sending notice via email,
        ScrumOnDemand will send notices to Talent at the e-mail address maintained in ScrumOnDemand’s records for Talent.  Talent must send notices to
        ScrumOnDemand at ScrumOnDemand’s then current e-mail address published on the Website.  E-mail notices are deemed written notices for all purposes
        for which written notices may be required.  E-mail notices are deemed received the business day after transmission if properly addressed to the intended
        recipient and so long as the sender does not have reason to believe the email was not received.
      </base-body>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionTermsAndConditions',
  }
</script>
